<template>
  <div class="single-bridge-common">
    <div class="info-left">
      <div class="info">
        <div class="info-title">
          <i></i>
          <span>基本信息</span>
        </div>
        <div class="info-con">
          <ul class="info-list" style="display: block">
            <li :title="content.name" style="width: 100%">桥梁名称：<strong>{{content.name}}</strong></li>
            <li :title="content.type" style="width: 100%">结构类型：<strong>{{content.type}}</strong></li>
            <li :title="content.bridgeFixUp" style="width: 100%">桥跨布置：<strong>{{content.bridgeFixUp}}</strong></li>
            <li :title="content.highwayLevel" style="width: 100%">公路等级：<strong>{{content.highwayLevel}}</strong></li>
            <li :title="content.designHours" style="width: 100%">设计时速：<strong>{{content.designHours}}</strong></li>
            <li :title="content.bridgeWidth" style="width: 100%">主桥桥面宽度：<strong>{{content.bridgeWidth}}</strong></li>
            <li :title="content.loadLevel" style="width: 100%">汽车荷载等级：<strong>{{content.loadLevel}}</strong></li>
            <li :title="content.windStandard" style="width: 100%">抗风设计标准：<strong>{{content.windStandard}}</strong></li>
            <li :title="content.temperatureStandard" style="width: 100%">设计温度标准：<strong>{{content.temperatureStandard}}</strong></li>
          </ul>
        </div>
      </div>
      <div class="info">
        <div class="info-title">
          <i></i>
          <span>其他信息</span>
        </div>
        <div class="info-con">
          <ul class="info-list">
            <li :title="content.constructionUnit">建设单位：<strong>{{content.constructionUnit}}</strong></li>
            <li :title="content.completeTime">合龙时间：<strong>{{content.completeTime}}</strong></li>
            <li :title="content.construction2Unit">承建单位：<strong>{{content.construction2Unit}}</strong></li>
            <li :title="content.openTime">通车时间：<strong>{{content.openTime}}</strong></li>
            <li :title="content.maintenanceUnit">养护单位：<strong>{{content.maintenanceUnit}}</strong></li>
            <li :title="content.longitude">经度：<strong>{{content.longitude}}</strong></li>
            <li :title="content.ownershipUnit">权属单位：<strong>{{content.ownershipUnit}}</strong></li>
            <li :title="content.latitude">纬度：<strong>{{content.latitude}}</strong></li>
            <li :title="content.designUnit">设计单位：<strong>{{content.designUnit}}</strong></li>
            <li :title="content.remark">备注：<strong>{{content.remark}}</strong></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="info-right">
      <div class="info-title" style="border: none">
        <i></i>
        <span>桥梁照片</span>
      </div>
      <div class="info-img">
        <img :src="'/bridge' + content.url" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content:{}
    };
  },
  created() {
    this.getData()
  },
  methods: {
    getData(){
      let bridgeList =JSON.parse(localStorage.getItem('bridgeList'))
      let baseUrl = localStorage.getItem('baseUrl')
      let id = ''
      if (bridgeList && bridgeList.length > 0){
        for (let item of bridgeList){
          if (baseUrl === item.baseUrl){
            id = item.id
            break
          }
        }
      }
      this.$http.get('/bridge/info/detail',{params:{id:id}}).then(res => {
        if (res.success){
          this.content = res.data
        }
      })
    }
  },
};
</script>
<style scoped>
  .single-bridge-common{
    display: flex;
    justify-content: space-between;
  }
  .info-left{
    width: 39%;
    height: 100%;
  }
  .info-title{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D9D9D9;
  }
  .info-title i{
    display: inline-block;
    width: 6px;
    height: 22px;
    background: #3582EB;
    border-radius: 4px;
    margin-right: 10px;
  }
  .info-title span{
    color: #3582EB;
    font-size: 18px;
    font-weight: bold;
  }
  .info-list{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
  }
  .info-list li{
    width: 60%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .info-list li:nth-child(2n){
    width: 40%;
  }
  .info-right{
    width: 60%;
    height: 100%;
  }
  .info-img{
    width: 100%;
    height: auto;
    max-height: 100%;
  }
  .info-img img{
    width: 100%;
  }
</style>
